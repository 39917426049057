/* ==========================================================================
   Helper classes
   ========================================================================== */

* {box-sizing:border-box;}
.group:after {content: ""; display: table; clear: both;}

.hidden {display: none !important; visibility: hidden;}
.visuallyhidden {border: 0; clip: rect(0 0 0 0); height: 1px; margin: -1px; overflow: hidden; padding: 0; position: absolute; width: 1px;}
.visuallyhidden.focusable:active, .visuallyhidden.focusable:focus {clip: auto; height: auto; margin: 0; overflow: visible; position: static; width: auto;}
.invisible {visibility: hidden;}

.clearfix:before, .clearfix:after {content: " "; display: table;}
.clearfix:after {clear: both;}
.clearfix {*zoom: 1;}

::-moz-selection {background: #b3d4fc; text-shadow: none;}
::selection {background: #b3d4fc; text-shadow: none;}
audio, canvas, iframe, img, svg, video {vertical-align: middle;}
textarea {resize: vertical;}


/* ==========================================================================
   Print styles
   ========================================================================== */

@media print {
    *, *:before, *:after {background: transparent !important; color: #000 !important; box-shadow: none !important; text-shadow: none !important;}
    a, a:visited {text-decoration: underline;}
    a[href]:after {content: " (" attr(href) ")";}
    abbr[title]:after {content: " (" attr(title) ")";}
    a[href^="#"]:after, a[href^="javascript:"]:after {content: "";}
    pre, blockquote {border: 1px solid #999; page-break-inside: avoid;}
    thead {display: table-header-group;}
    tr, img {page-break-inside: avoid;}
    img {max-width: 100% !important;}
    p, h2, h3 {orphans: 3; widows: 3;}
    h2, h3 {page-break-after: avoid;}
}


/* ==========================================================================
   Styles
   ========================================================================== */


@import 'mixins';

$white : #ececec;
$red  : #eb1c23;
$grey : #ecedeb;



[data-aos="aos-up"] {
  opacity: 0;
  transition-property: transform, opacity;

  &.aos-animate {
    opacity: 1;
  }

  @media screen and (min-width: 768px) {
    transform: translateY(50px);

    &.aos-animate {
      transform: translateX(0);
    }
  }
}


html {height:100%;}
body {height:100%; font-family:'Karla', sans-serif; background-color:#ecedeb;}

#wrapper {opacity:0;}
#wrapper, #wrapper-container {height:100%;}
#wrapper-container {overflow:hidden;}

.vtable {display:table; height:100%; width:100%;}
.vcell {display:table-cell; height:100%;}
.vbottom {vertical-align:bottom;}
.vmiddle {vertical-align:middle;}
.vtop {vertical-align:top;}

.main-container {
	&.background-grey {background:url(../images/background-grey.jpg) top left repeat; background-size:282px 236px;}
}

p {
	position:relative; font-size:16px; line-height: 1.8em;
	@include breakpoint("mobile") {font-size:15px; line-height:1.33em;}
	&.larger-p {
		font-size:30px; line-height: 1.3em;
		@include breakpoint("mobile") {font-size:18px;}
	}

	&.p-anim {@include transition(color .3s cubic-bezier(.4,0,0,1)); transition-delay:0.3s;}
}

li {
	font-size:16px; line-height: 1.8em;
	@include breakpoint("mobile") {font-size:15px; line-height:1.33em;}
}

.bg-white {background-color:#fff;}
.bg-grey {background-color:$grey;}
.bg-red {background-color:$red;}
.bg-blue {background-color:#022e2f;}
.bg-darkblue {background-color:#133459;}
.txt-red {color:$red;}
.txt-white {color:#fff;}
.txt-black {color:#000;}
.txt-center {
	text-align:center;
	@include breakpoint("mobile") {text-align:left;}
}
.txt-right {text-align:right;}
.empty {display:none;}
.bg-blue-green {background: linear-gradient(90deg, rgba(33,138,193,1) 0%, rgba(93,188,79,1) 100%);}

.display-mobile {
	display:none;
	@include breakpoint("mobile") {display:inline-block; width:auto;}
}

.lang {
	position:absolute; top:60px; right:60px; z-index:399; color:#fff; font-size:20px; opacity:0;
	@include breakpoint("mobile") {top:30px; right:30px;}
	a {
		color:#fff; text-decoration:none;
		&.currentLang {color:$red;}
	}

	&.dark-lang {
		color:#000;
		a {
			color:#000;
			&.currentLang {color:$red;}
		}
	}
}

h1 {
	position:relative; margin-top:40px; margin-bottom:40px; font-size:90px; line-height:0.9em; z-index:100;
	@include breakpoint("mobile") {font-size:30px; margin-top:20px; margin-bottom:20px;}

	&.larger-h1 {
		font-size:120px;
		@include breakpoint("mobile") {font-size:32px;}
	}
	
	span {display:block;}
	span:last-child {
		width:100%;
		&.span-center {
			text-align:center;
			@include breakpoint("mobile") {text-align:left;}
		}
		&.span-left {text-align:left;}
		&.span-red {color:$red;}
	}

	&.h1-demileft {
		margin-right:-30px;
		span {
			@include breakpoint("mobile") {
				width:auto; display:inline-block; margin-right:10px;
			}
		}
	}
	&.h1-demiright {
		display:inline-block; margin-left:-30px;
		@include breakpoint("mobile") {display:none;}
	}

	

	&.title-overlap-left {
		margin-left:-60%;
		@include breakpoint("mobile") {margin-left:0;}
	}

	&.title-overlap-right {
		margin-right:-3em;
		span:first-child {}
		span:last-child {}
	}

	&.deco-lines {
		&::before {content:""; display:block; position:absolute; bottom:-60px; left:0; right:0; height:60px; background:url(../images/lines.png) top left no-repeat; background-size:contain;}
	}
}

h2.deco-title {
	display:inline-block; margin:0 0 20px; text-align:left; font-size:50px; font-weight:normal; line-height:1;
	@include breakpoint("mobile") {font-size:30px; margin-bottom:0;}
	span:first-child {
		display:block;
		&::after {
			content:""; display:inline-block; position:relative; top:24px; left:20px; width:40px; height:9px; background-color:#fff; vertical-align:top;
			@include breakpoint("mobile") {top:15px; left:12px; height:6px; width:25px;}
		}
	}
	span:last-child {
		display:block;
		&::before {
			content:""; display:inline-block; position:relative; top:19px; left:0; width:48px; height:17px; margin-right:14px; background:url(../images/wave.svg) top left no-repeat; vertical-align:top;
			@include breakpoint("mobile") {top:14px; margin-right:10px; width:25px;}
		}
	}
}

.txt-red {
	h2.deco-title {
		span:first-child {
			&::after {background-color:$red;}
		}
		span:last-child {
			&::before {background-image:url(../images/wave-red.svg)}
		}
	}
}


h3 {
	position:relative; display:inline-block; color:#000; font-size:38px; padding-left:4px; padding-right:4px;
	&.redbar-title::before {
		content:""; display:block; position:absolute; bottom:0; left:0; width:100%; height:18px; background-color:$red; z-index:-1;
		@include breakpoint("mobile") {height:14px;}
	}
	&.no-padding {padding:0; margin:0 0 20px 0;}
	@include breakpoint("mobile") {margin-top:0; font-size:26px;}
}

#eyes {
	position:absolute; top:100px; right:15px;
	@include breakpoint("mobile") {position:relative; top:0; width:80%; max-width:313px; right:auto;}
}

.row-video {
	position:relative; height:auto; opacity:0;
	
	.mejs-overlay-loading {display:none;}

	& > a {
		position:absolute; top:60px; left:154px; z-index:200; opacity:0;
		@include breakpoint("mobile") {display:none;}
	}

	.videoContainer {
		position:relative; left:94px; width:calc(100% - 94px); height:100%; overflow:hidden;
		@include breakpoint("mobile") {left:60px; width:calc(100% - 60px);}

		.mejs-container {
			position: absolute; top: 0; left: 0; width: 100% !important; height:100% !important;
		}
		.video, .video-in-content {
			position: absolute; top: 0; left: 0; width: 100%; height:100%;
			@include breakpoint("tablet") {width:100%; height:auto;}
		}
		.mejs-overlay-play {
			width:100% !important; height:100% !important;
			div {
				display:none; width:200px; height:200px; background-size:cover; margin:-100px 0 0 -100px;
				
				@include breakpoint("small") {width:60px; height:60px; background-size:cover; margin:-30px 0 0 -30px;}		
			}
		}

		.mejs-overlay:hover .mejs-overlay-button {background-position:0 -200px !important;}

		.mejs-poster {
			width:100% !important; height:100% !important; background-size:cover;
		}

		.mejs-container .mejs-controls { visibility:hidden !important; }
	}
}

.play {
		position:relative; display:table; display:none; width:100%; height:100%; text-align:center; cursor:pointer;
		& > div {display:table-cell; vertical-align:middle; pointer-events:none;}
		& > div > div {
			height:237px; display:inline-block; pointer-events:none;
			@include breakpoint("tablet") {height:100px;}
			@include breakpoint("mobile") {height:50px;}
		}
		a {
			float:left; display:block; width:237px; height:237px; text-indent:-10000px; overflow:hidden; pointer-events:none;
			@include breakpoint("tablet") {width:100px; height:100px;}
			@include breakpoint("mobile") {width:50px; height:50px;}

			@include transition(transform .3s ease);
			span {
				display:block; width:237px; height:237px; background:url(../images/play.svg) center left no-repeat; background-size:229px 229px; opacity:0.8; pointer-events:none;
				@include breakpoint("tablet") {width:100px; height:100px; background-size:contain;}
				@include breakpoint("mobile") {width:50px; height:50px;}
			}
			&.black-play span {background-image:url(../images/play_black.svg)}
		}

		&:hover a {transform: scale(0.9);}

		label {
			float:left; display:table; height:100%; margin-left:40px; padding-left:40px; color:#fff; font-size:40px; border-left:solid #fff 6px; 
			@include breakpoint("tablet") {font-size:36px; border-left:solid #fff 4px;}
			@include breakpoint("mobile") {display:none;}
			span {display:table-cell; padding-bottom:11px; vertical-align:middle; text-align:left;}
		}
	}

#transition {
	display:none; position:fixed; top:0; left:0; right:0; bottom:0; z-index:500; overflow:hidden;
	#h-panel-top {
		position:fixed; top:0; left:94px; width:0; height:100%; background-color:$white;
		@include breakpoint("mobile") {left:60px;}
	}
	#h-panel-bottom {position:fixed; bottom:0; right:0; width:0; height:50%; background-color:$white;}
	.wave {
		@include breakpoint("mobile") {margin-left:0;}
	}
}

.wave, .waveover {
	position:absolute; top:50%; left:50%; z-index:900; width:48px; height:48px; margin-left:-24px; margin-top:-24px; overflow:hidden;
	.wave-container {position:absolute; top:48px; left:0; width:48px; height:48px;}
	.wave-top-bar {position:absolute; top:0; left:0; width:48px; height:9px; background-color:$red;}
	svg {height:100px; width:48px; transition: 1s ease-out;}
	path {stroke:$red; stroke-width: 8px; stroke-linecap:square; fill: none;}
	img {position:absolute; top:0; left:0;}
}

#sidebar .waveover {
	 pointer-events:none;
	.wave-container {top:0px; transform:scale(0.6) rotate(-90deg); pointer-events:none;}
	.wave-top-bar {position:absolute; top:8px; left:4px; width:41px; height:5px; background-color:$red; pointer-events:none;}
	.wave-bottom-bar {position:absolute; bottom:8px; left:4px; width:41px; height:5px; background-color:$red; pointer-events:none;}
	svg {margin-top:-9px; pointer-events:none;}
	path {stroke-width: 5px; pointer-events:none;}
}

.team .waveover {
	top:auto; bottom:-11px; left:0; width:100%; margin-left:0; margin-top:0; z-index:-1;
	.wave-container {top:0; width:100%;}
	svg {width:100%;}
}

.header {
	position:relative; width:100%; height:100%; padding:60px 94px 60px 154px; background-color:#ececec; overflow:hidden;
	@include breakpoint("mobile") {padding:0;}

	a {
		opacity:0;
		@include breakpoint("mobile") {display:none;}
	}

	h1 {
		position:absolute; top:0; left:94px; bottom:0; margin:0; padding:150px 0 150px 150px; width:60%; color:$red; font-size:70px; line-height:1.1em; font-weight:500; pointer-events:none;

		span span {
			display:inline-block; position:relative; width:auto; z-index:1;
			&::before {
				content:""; display:block; position:absolute; bottom:14px; left:-2px; right:-2px; height:20px; background-color:#fff; z-index:-1;
				@include breakpoint("mobile") {height:10px; bottom:8px;}
			}
		}

		@include breakpoint("mobile") {left:60px; font-size:40px; padding-left:70px; width:100%;}
		@include breakpoint("smallmobile") {left:60px; font-size:20px; padding:30px 0 0 15px;}

		&.red-white {
			color:#fff;
			span span {
				&::before {
					background-color:$red;
				}
			}
		}
	}

	.header-background {position:absolute; top:0; left:0; right:0; bottom:0; background-position:center center; background-size:cover; z-index:0;}
	* {position:relative;}
}

.content-pastille {
	@include breakpoint("mobile") {padding-top:105px;}
}

.pastille {
	position:absolute; top:50%; left:50%; width:220px; height:220px; margin:-110px 0 0 -110px;
	@include breakpoint("mobile") {transform:scale(0.8); top:0; margin-top:-70px;}
	.circle {
		position:absolute; top:0; left:0; width:220px; height:220px; background-color:$grey; border-radius:110px; background:url(../images/pastille.png); background-size:cover; z-index:0;
		&.circle-en {background-image:url(../images/pastille_ang.png);}
	}		
	.wave-fix-top {position:absolute; top:50%; left:50%; margin-left:-32px; margin-top:-21px; width:64px; height:14px; background-color:$red; z-index:5;}
	.wave-fix {display:block; position:absolute; top:50%; left:50%; margin-left:-32px; margin-top:-7px; width:64px; height:42px;}
}

#sidebar {
	position:fixed; top:0; left:0;  width:94px; height:100%; z-index:450; overflow:hidden; cursor:pointer;
	//-webkit-box-shadow: 10px 0px 30px 0px rgba(0,0,0,0.07); -moz-box-shadow: 10px 0px 30px 0px rgba(0,0,0,0.07); box-shadow: 10px 0px 30px 0px rgba(0,0,0,0.07);

	@include breakpoint("mobile") {width:60px;}

	&:hover {
		//-webkit-box-shadow: 10px 0px 30px 0px rgba(0,0,0,0.0); -moz-box-shadow: 10px 0px 30px 0px rgba(0,0,0,0.0); box-shadow: 10px 0px 30px 0px rgba(0,0,0,0.0);
	}

	#bar {
		position:relative; width:94px; height:100%; background-color:#f5f5f5; @include transition(background-color .3s cubic-bezier(.4,0,0,1));
		@include breakpoint("mobile") {width:60px;}

		#logo_small {
			position:absolute; top:60px; left:50%; margin-left:-17px; width:34px; opacity:0;
			@include breakpoint("mobile") {top:30px;}
			svg {fill:$red; @include transition(fill .3s cubic-bezier(.4,0,0,1));}
		}

		.waveover {left:-48px;}

		&.has-hover:hover {
			background-color:$red;
			#logo_small svg {fill:#fff;}
			.wave-container path, #logo_small path {stroke:#fff;}

			.wave-top-bar, .wave-bottom-bar {background-color:#fff;}

			.social {
				svg {fill:#fff; stroke:#fff;}
				a:first-child svg {stroke:#ffffff;} 
				span {background-color:#fff;}
			}
		}

		.social {
			position:absolute; bottom:45px; left:50%; margin-left:-7px; width:15px; line-height:1; text-align:center; opacity:0;
			@include breakpoint("mobile") {bottom:30px;}
			svg {display:inline-block; width:15px; height:15px; margin-top:12px; fill:$red; stroke:$red; @include transition(fill .3s cubic-bezier(.4,0,0,1));}
			a:first-child svg {stroke:$red; @include transition(stroke .3s cubic-bezier(.4,0,0,1));}
			span {display:inline-block; width:1px; height:25px; margin-top:12px; background-color:$red; @include transition(background-color .3s cubic-bezier(.4,0,0,1));}
		}

	}

	&.open {
		width:460px;



		@include breakpoint("mobile") {width:100%;}
		#bar {
			background-color:$red;
		 	#logo_small {fill:#fff;}
		 	path {stroke:#fff;}
			.wave-top-bar, .wave-bottom-bar {background-color:#fff;}

			#logo_small svg {fill:#fff;}
			.wave-container path, #logo_small path {stroke:#fff;}

			.wave-top-bar, .wave-bottom-bar {background-color:#fff;}

			.social {
				svg {fill:#fff; stroke:#fff;}
				a:first-child svg {stroke:#ffffff;} 
				span {background-color:#fff;}
			}
		 }
	}

	counter-reset:menu-counter;

	#menu {
		position:absolute; top:0; left:94px; width:366px; height:100%; overflow: hidden;
		@include breakpoint("mobile") {left:60px; width:calc(100% - 60px);}
		ul {
			position:relative; margin:0; padding:0; overflow:hidden;
			li {
				position:relative; left:-366px; margin:0; width:100%; padding:0; list-style-type:none; text-align:left; border-bottom:solid 1px $red; background-color:#f5f5f5; overflow:hidden;
				

				&:last-child {border-bottom:none;}
				&::before {content:""; position:absolute; top:0; left:0; bottom:0; right:0; display:block; background-color:$red; z-index:0; transform: scaleX(0); transform-origin:center left; transition: transform .3s cubic-bezier(.4,0,0,1);} 

				a {
					display:block; padding:25px 15px 25px 58px; position:relative; display:block; font-size:35px; color:$red; text-decoration:none; transform: translateY(60px); @include transition(padding-left .3s cubic-bezier(.4,0,0,1), color .3s cubic-bezier(.4,0,0,1));
					@include breakpoint("mobile") {font-size:22px; padding:20px 15px 20px 58px;}
					&::before {
						display:inline-block; position: relative; top: -12px; left: -9px; counter-increment:menu-counter; content: "0" counter(menu-counter); color:$red; font-size:20px; @include transition(color .3s cubic-bezier(.4,0,0,1));
						@include breakpoint("mobile") {font-size:15px; top:-9px; left:-6px;}
					}
				}

				&:hover, &.menu-selected {
					
					&::before {/*transform-origin: center left;*/ transform: scaleX(1);}
					a {
						color:#fff; padding-left:64px;
						&::before {color:#fff;}
					}
				}

				&:last-child {
					padding-left:78px; text-align:left;
					@include breakpoint("mobile") {padding-left:70px;}

					&:before {display:none;}
					a {
						display:inline-block; font-size:14px; text-decoration:underline; transform:none; padding:25px 5px;
						&:hover {color:#000;}
						&:before {display:none;}
					}
					
					&:hover a {color:$red;}
					&:hover a:hover {color:#000;}

					&.menu-selected {
						a {
							color:$red;
							&:hover {color:#000;}
						}

					}
				}
			}
		}
		span {position:relative; left:-366px; display:block; width:100%; background-color:#f5f5f5; height:100px; z-index:0;}
	}
}


.visionneuse-projet {
	display:none; opacity:0; position:fixed; top:0; left:94px; right:0; bottom:0; z-index:445;  overflow:auto; padding:0 30px;
	
	.bg-visionneuse {position:absolute; top:0; left:0; right:0; height:100%; background-color:rgba(#000, 0.8);}
	.visionneuse-container {position:relative; width:100%; display:none; opacity:0; max-width:1140px; width:100%; margin:0 auto; }

	@include breakpoint("mobile") {left:60px; padding:0;}

	.close {
		position:fixed; top:60px; right:0; display:block; z-index:301; padding:10px; background-color:#000; opacity:0;
		@include breakpoint("mobile") {top:30px;}
		span {display:block; width:20px; height:20px; background:url(../images/close.svg) #000 top left no-repeat; background-size:cover; }
	}

	.visionneuse-content {
		position:relative; max-width:1140px; width:100%; margin:60px auto; background-color:#fff; z-index:300;
		@include breakpoint("mobile") {margin:0;}
	}

	.wave-top-bar {background-color:#fff;}
	path {stroke:#fff;}

	.projet-content {position:relative;}
	.projet-header, .projet-row {
		position:relative; padding:100px;
		@include breakpoint("mobile") {padding:15px;}
	}

	.projet-row {
		padding:100px;
		@include breakpoint("mobile") {padding:40px 15px 40px 15px;}

		 .videocontent {width:100%;}

		 .video-ordi {
		 	text-align:center !important;
		 	.ordi {
		 		width:765px; height:531px; background:url(../images/video-ordi.jpg) top left no-repeat; background-size:contain; margin:0 auto; position:relative; 
		 		@include breakpoint("mobile") {width:100%; height:auto; background:none; margin-bottom:20px;}
		 	}
		 	.videocontent {margin:0 auto; width:650px; padding-top:39px;}
		 	.back-video {
		 		position:absolute; top:50%; left:0; background:none; width:25px; margin-top:-13px; transform:scaleX(-1);
		 		@include breakpoint("mobile") {position:relative; display:inline-block; top:auto; left:auto; margin-right:10px;}
		 	}
		 	.next-video {
		 		position:absolute; top:50%; right:0; background:none; width:25px; margin-top:-13px;
		 		@include breakpoint("mobile") {position:relative; display:inline-block; top:auto; right:auto;}
		 	}

		 	.slick-list {
		 		width:650px; margin:0 auto;
		 		@include breakpoint("mobile") {width:100%;}
		 	}
		 }

		 .mejs-controls .mejs-time-rail .mejs-time-loaded {background: linear-gradient(rgba(#eb1c23,.8),rgba(#bf171d,.8)) !important;}
		 .mejs-overlay-button {
		 	background:url(../images/play_btn.svg) no-repeat !important;
		 	@include breakpoint("mobile") {width:50px; height:50px; margin:-25px 0 0 -25px;}
		 }
		 .mejs-poster {background-size:cover;}

		&.double-image {padding:0;}
	}

	.projet-header {
		background-size:cover; background-repeat:no-repeat; min-height:800px;
		@include breakpoint("mobile") {min-height:450px;}
		@include breakpoint("mobile") {padding:100px 15px 40px 15px;}

		.projet-content {
			position:absolute; bottom:100px; left:100px; right:100px;
			@include breakpoint("mobile") {bottom:30px; left:30px; right:30px;}
		}

		.projet-col-2 .projet-col:last-child {
			padding:200px 15px 0 15px;
			&.nouvelle-top-padding {
				padding-top:540px;
				@include breakpoint("mobile") {padding-top:30px;}
			}
			@include breakpoint("mobile") {padding:30px 0 0 0;}
		}
		img {
			@include breakpoint("mobile") {width:100%; max-width:438;}
		}
	}
	.projet-col-2 .projet-col {
		position:relative; width:50%; float:left;
		@include breakpoint("mobile") {width:100%;}
		img {margin-bottom:30px;}
		&.projet-content-img-only {
			img {margin-bottom:0px;}
		}

		.img-bloc-center {
			margin-left:-100px; margin-bottom:0;
			@include breakpoint("mobile") {margin-left:0; width:100%; margin-bottom:0;}
		}
		&:first-child {
			position:absolute; top:0; left:0; bottom:0; float:none;
			@include breakpoint("mobile") {position:relative;}
		}
		&:last-child {
			margin-left:50%; padding:0px 15px 0 15px;
			@include breakpoint("mobile") {margin-left:0; padding:0;}
		}
	}

	.projet-col-2.title-only .projet-col {
		position:relative; height:600px;
		@include breakpoint("mobile") {height:auto;}
		&:first-child {width:66%;}
		&:last-child {margin-left:0;}
		h1 {line-height:1.1em;}
	}

	.projet-col-2.projet-image-left .projet-col {
		&:first-child {
			top:-100px;left:-100px; bottom:-100px; background-size:cover; width:calc(50% + 100px); background-position:center center;
			@include breakpoint("mobile") {top:0; left:0; bottom:0; width:100%; height:250px; margin-bottom:30px;}
		}
		&:last-child {
			padding-left:100px; padding-right:0;
			@include breakpoint("mobile") {padding-left:0;}
		}
	}	

	.projet-col-2.projet-image-right .projet-col {
		&:first-child {position:relative; top:auto; left:auto; bottom:auto; padding-right:100px;}
		&:last-child {
			position:absolute; top:-100px; left:50%; bottom:-100px; right:-100px; float:none; margin-left:0; padding:0; width:auto; background-size:cover;
			@include breakpoint("mobile") {position:relative; top:auto; left:auto; right:auto; bottom:auto; height:250px; margin-top:30px;}
		}
	}	

	.projet-list {
		li {
			position:relative; font-size:20px; font-weight:bold; list-style-type:none; margin:0 0 15px 0;
			@include breakpoint("mobile") {font-size:16px;}
			&::before {
				position:absolute; top:2px; left:-45px; display:block; content:""; width:32px; height:32px; background:url(../images/check.svg) top left no-repeat; background-size:contain;
				@include breakpoint("mobile") {width:24px; left:-35px;}
			}
		}
	}	

	.nouvelle {
		h1 {line-height:1.6em;}
		h3 {text-align:left; font-weight:bold; padding:0;}
		p {text-align:left; margin-bottom:20px;}
		ul {margin-top:0; padding-top:0; padding-left:19px; margin-bottom:25px;} 
		li {line-height:1.3em; margin:5px 0 0 0;}
		a {
			color:#000; font-weight:bold;
			&:hover {color:$red;}
		}
		.btn {color:#fff; margin-top:30px;}
	}



	.video {
		padding:0;
		img, p {margin-bottom:0;}
	}

	h1, p {margin:0;}
	p {line-height:1.4em;}
	h1 {
		font-size:60px;
		@include breakpoint("mobile") {font-size:32px; margin-bottom:20px;}
	}
	h2 {
		margin:0; font-size:40px;
		@include breakpoint("mobile") {font-size:24px; margin-bottom:0px;}	
	}
	h3 {
		font-size:22px; font-weight:normal;
		@include breakpoint("mobile") {font-size:18px; margin-top:10px; padding:0;}
	}
	.stats p {margin-bottom:15px;}

	.img-only {
		height:500px; width:100%; background-size:cover; background-position:center center;
		@include breakpoint("mobile") {height:180px;}

		&.image-portrait {
			height:750px;
			@include breakpoint("mobile") {height:340px;}
		}
	}

	.no-projet-padding {padding:0;}

	.txt-red h3 {color:$red;}
	.projet-title {width:100%;}

	.prix-main-container {
		@include rowMachine(4, 1%); background:$red; background-size:cover; padding: 10px; float: left;
		@include breakpoint("mobile") {@include rowMachine(2, 1%);}
		@include breakpoint("smallmobile") {@include rowMachine(1, 1%);}
	}

	.prix-container {display:block; position:relative; height:0; overflow:hidden; padding-top:198px / 198px * 100%;}
	.prix-content {
		position: absolute; top: 0; left: 0; width: 100%; height: 100%;	text-align:center; padding:10px; color:#fff; text-align:left;
		p {font-size:14px; line-height:1.2em;}
		h2 {
			@include breakpoint("mobile") {margin-bottom:20px;}
		}
	}

}

.row-padding {
	padding:100px 15px 100px 15px;
}

.row {
	position:relative; left:94px; width:calc(100% - 94px); background-size:cover;
	@include breakpoint("mobile") {left:60px; width:calc(100% - 60px);}

	.row-content {
		position:relative; max-width:1140px; width:100%; margin:0 auto; z-index:300;
		//p {@include transition(color .3s ease); transition-delay: .1s;}
	}

	.row-full {
		position:relative; width:100%; margin:0 auto; z-index:300;
	}

	&.col-1 .row-content {
		& > div {
			width:100%; padding:100px 15px 100px 15px;
			@include breakpoint("mobile") {padding:30px 15px 30px 15px;}
			&.clients {padding-top:30px;}
		}
		&.small-bottom-padding > div {padding-bottom:30px;}
		&.no-bottom-padding > div {padding-bottom:0;}
		&.no-top-padding > div {padding-top:0;}
	}

	&.col-1-3 .row-content {
		& > div:first-child {
			width:40%; padding:100px 15px 100px 15px;
			@include breakpoint("mobile") {width:100%; padding:60px 15px 60px 15px;}	
		}
		& > div:last-child {
			width:60%; padding:100px 15px 100px 15px;
			@include breakpoint("mobile") {width:100%; padding:60px 15px 60px 15px;}	
		}
	}

	.bg-elements {
		position:absolute; top:0; left:0; bottom:0; right:0;
		@include breakpoint("mobile") {display:none;}

		&.with-graphic > div {
			overflow:inherit;
			.graphic {position:absolute; top:0; left:0; transform: translate(0,-50%);}
		}

		.over-bg {
			position:absolute; margin-left:50%; width:50%; height:100%;
			&::before {content:""; display:block; position:absolute; top:0; left:0; bottom:0; right:0; background-color: $red; transform: scaleX(0); transform-origin:center right; transition: transform .3s cubic-bezier(.4,0,0,1);  transition-delay: .2s;}
			
		}
	}



	&.bgcolor-left, &.bgcolor-right {
		.row-content.txt-white {
			@include breakpoint("mobile") {background-color:$red;}
		}
		.row-content > div {
			width:50%;
			@include breakpoint("mobile") {width:100%;}
		}
	}

	&.bgcolor-left {
		.row-content > div:first-child {
			float:left; padding:100px 60px 100px 0;
			@include breakpoint("mobile") {padding:30px 15px 30px 15px;}
		}
		.row-content > div:last-child {
			float:right; padding:100px 15px 100px 60px;
			@include breakpoint("mobile") {padding:30px 15px 30px 15px;}

		}
		.bg-elements {
			& > div:first-child {
				position:absolute; top:0; left:0; bottom:0; width:50%; overflow:hidden;
				.bg-container {position:absolute; top:0; left:0; right:0; bottom:0; background-position:center center; background-size:cover;}
			}
		}

		&.bgcolor-red .bg-elements > div:first-child {background-color:$red;}
		&.bgcolor-white .bg-elements > div:first-child {background-color:#fff;}

		.image-portrait {
			position:absolute; top:0; right:0; bottom:0;
			@include breakpoint("mobile") {position:relative; bottom:auto; top:auto; padding-top:0 !important;}
			& > div {
				position:absolute; top:110px; left:0px; right:0px; bottom:-100px; overflow:hidden;
				@include breakpoint("mobile") {position:relative; bottom:auto; top:auto;}
				& > div {
					position:absolute; top:0; left:0px; right:0px; bottom:0; background-size:cover; background-position:center center;
					@include breakpoint("mobile") {position:relative; height:250px; bottom:auto; top:auto;}
				}
			}
		}

		&.bgcolor-overlap-top {
			&.bgcolor-overlap-top-margin {margin-top:260px;}
			.bg-elements > div:first-child {top:-140px;}
			.image-portrait > div {
				top:0; bottom:-60px;
				@include breakpoint("mobile") {bottom:0;}
			}
		}
	}


	&.bgcolor-right {
		
		.row-content > div:first-child {
			float:left; padding:100px 15px 100px 60px;
			@include breakpoint("mobile") {padding:30px 15px 30px 15px;}
		}
		.row-content > div:last-child {
			float:right; padding:100px 15px 100px 60px;
			@include breakpoint("mobile") {padding:30px 15px 30px 15px;}
		}
		.bg-elements {
			& > div:last-child {
				position:absolute; top:0; left:50%; bottom:0; width:50%; overflow:hidden;
				.bg-container {position:absolute; top:0; left:0; right:0; bottom:0; background-position:center center; background-size:cover;}
			}
		}

		&.bgcolor-red .bg-elements > div:last-child {background-color:$red;}
		&.bgcolor-white .bg-elements > div:last-child {background-color:#fff;}

		.image-portrait {
			position:absolute; top:0; left:0; bottom:0;
			& > div {
				position:absolute; top:110px; left:0px; right:0px; bottom:-100px; overflow:hidden;
				& > div {position:absolute; top:0; left:0px; right:0px; bottom:0; background-size:cover; background-position:center center;}
			}
		}

		&.bgcolor-overlap-top {
			&.bgcolor-overlap-top-margin {
				margin-top:260px;
				@include breakpoint("mobile") {margin-top:0;}
			}
			.bg-elements > div:last-child {top:-140px};
			.image-portrait > div {
				top:0; bottom:-60px;
				@include breakpoint("mobile") {bottom:0;}
			}
		}
	}




	&.image-left {
		.row-content > div {
			width:50%;
			@include breakpoint("mobile") {width:100%;}
		}
		.row-content > div:first-child {
			float:left; padding:100px 100px 100px 15px;
			&.empty {padding:0;}
			@include breakpoint("mobile") {padding:30px 15px 30px 15px;}
		}
		.row-content > div:last-child {
			float:right; padding:100px 15px 100px 60px;
			@include breakpoint("mobile") {padding:30px 15px 30px 15px;}	
		}
		.row-content > div.more-left-padding {
			padding-left:150px;
			@include breakpoint("mobile") {padding-left:15px;}	
		}

		.bg-elements {
			@include breakpoint("mobile") {display:block; position:relative; top:auto; bottom:auto; left:auto; right:auto; }		
			& > div:first-child {
				position:absolute; top:0; left:0; bottom:0; width:50%; overflow:hidden;
				@include breakpoint("mobile") {display:block; position:relative; top:auto; bottom:auto; left:auto; right:auto; width:100%;}

				.bg-container {
					position:absolute; top:0; left:0; right:0; bottom:0; background-position:center center; background-size:cover;
					@include breakpoint("mobile") {display:block; position:relative; top:auto; bottom:auto; left:auto; right:auto; height:250px;} 
				}
			}
		}

		.image-portrait {
			position:absolute; top:0; left:0; bottom:0;
			@include breakpoint("mobile") {position:relative; top:auto; left:auto; right:auto; bottom:auto;}
			& > div {
				position:absolute; top:110px; left:15px; right:60px; bottom:-100px; overflow:hidden;
				@include breakpoint("mobile") {position:relative; top:auto; left:auto; right:auto; bottom:auto;}
				& > div {
					position:absolute; top:0; left:0; right:0; bottom:0; background-size:cover; background-position:center center;
					@include breakpoint("mobile") {position:relative; top:auto; left:auto; right:auto; bottom:auto; height:250px; }
				}
			}
		}

		.image-landscape {
			position:absolute; top:0; left:0; bottom:0;
			@include breakpoint("mobile") {position:relative; top:auto; bottom:auto; padding-top:0 !important;}	
			
			& > div {
				position:absolute; top:110px; left:15px; right:0px; bottom:110px; overflow:hidden;
				@include breakpoint("mobile") {position:relative; top:auto; left:auto; right:auto; bottom:auto; height:250px;}
				& > div, & > a {display:block; position:absolute; top:0; left:0px; right:0px; bottom:0; background-size:cover; background-position:center center;}
			}
		}
	}

	&.image-right {
		.row-content > div {
			float:left; width:50%;
			@include breakpoint("mobile") {width:100%;}
		}
		.row-content > div:first-child {
			padding:100px 60px 100px 15px;
			@include breakpoint("mobile") {padding:30px 15px 30px 15px;}
		}
		.row-content > div:last-child {
			padding:100px 15px 100px 100px;
			@include breakpoint("mobile") {padding:0px 15px 30px 15px;}	
		}
		.bg-elements {
			& > div:last-child {
				position:absolute; top:0; left:50%; bottom:0; width:50%; overflow:hidden;
				.bg-container {position:absolute; top:0; left:0; right:0; bottom:0; background-position:center center; background-size:cover;}
				.bg-container-layer {position:absolute; top:60px; left:60px; right:60px; bottom:0; background-position:center center; background-size:cover; z-index:100;}
			}
		}

		&.col-1-3 .bg-elements {
			& > div:first-child {
				width:40%;
				@include breakpoint("mobile") {width:100%;}
			}
			& > div:last-child {
				left:40%; width:60%;
				@include breakpoint("mobile") {width:100%; left:0;}
			}
		}

		.image-portrait {
			position:absolute; top:0; right:0; bottom:0;
			@include breakpoint("mobile") {position:relative; top:auto; left:auto; right:auto; bottom:auto;}
			& > div {
				position:absolute; top:110px; left:60px; right:15px; bottom:-100px; overflow:hidden;
				@include breakpoint("mobile") {position:relative; top:auto; left:auto; right:auto; bottom:auto;}
				& > div {
					position:absolute; top:0; left:0px; right:0px; bottom:0; background-size:cover; background-position:center center;
					@include breakpoint("mobile") {position:relative; top:auto; left:auto; right:auto; bottom:auto; height:250px;}
				}
			}
		}

		.image-landscape {
			position:absolute; top:0; right:0; bottom:0;
			@include breakpoint("mobile") {position:relative; top:auto; bottom:auto; padding-top:0 !important;}	
			
			& > div {
				position:absolute; top:110px; left:0px; right:15px; bottom:110px; overflow:hidden;
				@include breakpoint("mobile") {position:relative; top:auto; left:auto; right:auto; bottom:auto; height:250px;}
				& > div, & > a {display:block; position:absolute; top:0; left:0px; right:0px; bottom:0; background-size:cover; background-position:center center;}
			}
		}
	}

	

	&.animbg {
		.bg-elements div:last-child {
			&::before {transform-origin: center left; transform: scaleX(1);}
		}
		p {color:#fff;}
	}
}

.no-bottom-padding {
	padding-bottom:0;
}

.row.image-left .row-content.no-top-padding > div:last-child {padding-top:0;}
.row.image-left .row-content.no-top-padding > div:first-child {padding-top:0;}
.row.image-left .row-content.more-top-padding > div:last-child {
	padding-top:160px;
	@include breakpoint("mobile") {padding-top:0px;}
}
.row.bgcolor-left .row-content.no-top-padding > div {
	padding-top:0;
	@include breakpoint("mobile") {padding-top:30px;}
}
.row.bgcolor-right .row-content.no-top-padding > div {
	padding-top:0;
	@include breakpoint("mobile") {padding-top:30px;}
}


.team {
	.employe-container {position:relative; float:left; width:25%;}
	.employe {
		position:relative; height: 0; overflow: hidden; padding-top: 706px / 720px * 100%; background:white;
		&::after {content:""; display:block; position:absolute; top:0; left:0; bottom:0; right:0; background-color:#000; opacity:0.2; z-index:10; @include transition(opacity .3s cubic-bezier(.4,0,0,1));}
		&:hover {
			&::after {opacity:0;}
		}
	}
	.employe-content {
		position: absolute; top: 0; left: 0; width: 100%; height: 100%;
		.employe-info {
			position:absolute; bottom:35px; left:25px; right:25px; overflow:hidden; z-index:15; pointer-events:none;
			h5 {position:relative; margin:0; padding:0; font-size:16px; z-index:2; display:inline-block; pointer-events:none;}
			p {position:relative; margin:5px 0 0 0; padding:0; font-size:14px; font-family: "Times New Roman", Times, serif; letter-spacing: 2px; z-index:2;  pointer-events:none; line-height:1em;}
		}
		span {display:block; position:absolute; top:0; left:0; right:0; bottom:0; background-size:cover; z-index:0;}
	}
}


.tirroir {
	.poignee {
		position:relative; padding:20px 15px; font-size:30px; text-align:center; color:#fff; cursor:pointer; @include transition(color .3s cubic-bezier(.4,0,0,1));
		@include breakpoint("mobile") {font-size:24px;}

		&::before {content:""; display:block; position:absolute; top:0; left:0; bottom:0; width:0; background-color:$white; @include transition(width .6s cubic-bezier(.4,0,0,1)); z-index:0;}
		span {position:relative;}
		svg {margin-left:10px; fill:#fff; width:16px; transform: rotate(-90deg); @include transition(all .3s cubic-bezier(.4,0,0,1))}

		&:hover {
			color:$red;
			&::before {width:100%;}
			svg {transform: rotate(0deg); fill:$red;}
		}
	}
	.tirroir-content {overflow:hidden;}
	.tirroir-content > div {background-position: center center; background-size:cover;}

	.tirroir-content .row > span {content:""; display:block; position:absolute; top:0; left:0; right:0; bottom:0; background-color:$red; z-index:450;}
	.tirroir-content .row:first-child > span {background-color:$white;}
	.tirroir-content .row:last-child > span {background-color:$red;}
	

	&.tirroir-closed .tirroir-content {height:0;}
	.row {left:0; width:100%;}
}


button:focus {outline:0;}

.accordion {
	position:relative; font-size:22px; font-weight:bold; width:100%; padding:35px 0; border:none; border-bottom:3px solid $red; color:$red; text-align:left; background:none; -webkit-appearance:none; -moz-appearance:none; appearance:none; transition: all .3s cubic-bezier(.4,0,0,1);
	@include breakpoint("mobile") {font-size:18px; padding:25px 30px 25px 0;}

	//svg {float:right; width:22px; height:22px; fill:$red; transition: all .3s cubic-bezier(.4,0,0,1);}
	svg {position:absolute; top:50%; margin-top:-11px; right:0; width:22px; height:22px; fill:$red; transition: all .3s cubic-bezier(.4,0,0,1);}

	&:hover {
		padding-top:30px; border-bottom:solid 8px $red;
		svg {transform:rotate(90deg);}
		//&::before {transform-origin: center left; transform: scaleX(1);}
		//color:#fff;
	}

	&.active {
		svg {transform:rotate(225deg);}
	}
}

.panel {
	display: none; height:0; overflow: hidden;
	&.with-visual {
		& > div {
			float:left;
			&:first-child {
				width:75%;  min-height:200px; 
				@include breakpoint("mobile") {width:100%; min-height:0;}
			}
			&:last-child {
				width:25%; margin-top:20px; text-align:right; position:absolute; top:0; bottom:0; right:0;
				@include breakpoint("mobile") {position:relative; width:100%; text-align:left; top:auto; bottom:auto; right:auto;}
			}
		}

		.service-visual {
			opacity:0; background-size:contain; background-position:center center; height:100%; background-repeat:no-repeat;
			@include breakpoint("mobile") {height:200px; }
		}
	}
}

.clients {
	.client {
		position:relative; float:left; width:20%;
		@include breakpoint("mobile") {width:33.33%;}
	}
	.client-container {position:relative; height:0; overflow:hidden; padding-top:198px / 198px * 100%; background:$grey;}
	.client-content {
		position: absolute; top: 0; left: 0; width: 100%; height: 100%;	text-align:center; padding-top:30px;
		@include breakpoint("mobile") {padding-top:0;}
		img {
			@include breakpoint("mobile") {width:100%;}
		}
	}
	a {
		display:inline-block; margin:60px 0;
		@include breakpoint("mobile") {margin:30px 0;}
	}
}

.projet-title {
	position:relative; border-bottom:3px solid $red; font-size:22px; font-weight:bold; text-align:left; padding-bottom:30px;
	@include breakpoint("mobile") {font-size:18px; padding-bottom:0;}
	span {
		display:block; float:right; cursor:pointer;
		@include breakpoint("mobile") {float:none; margin-top:15px; font-size:16px;}
	}
	svg {
		width:20px; fill:$red; margin-left:5px; transform:rotate(-90deg);
		@include breakpoint("mobile") {width:15px;}
	}
}

.row-projets-filters {
	.filters {
		position:absolute; top:59px; right:0px; font-weight:normal; font-size:16px; z-index:200; overflow:hidden; padding:0; height:0;
		@include breakpoint("mobile") {top:72px; right:0px; left:0px; padding:0 0 0 0; font-size:14px;}
		
	}
	.filters-container {
		position:relative; top:0px; background-color:#fff;
		@include breakpoint("mobile") {padding:0 15px;}
	}
	ul, li {list-style-type:none; margin:0; padding:0;}
	ul {position:relative; padding:10px 0; min-width:150px;}
	a {
		display:inline-block; text-decoration:none; color:$red; padding:5px 15px;
		@include breakpoint("mobile") {padding:5px 0;}
	}
}

.row-projets {
	z-index:0;
}


.main-projets {
	padding:0 15px 100px 15px;
	@include breakpoint("mobile") {padding-bottom:30px;}
	.row-content > div {
		float:left; @include rowMachine(3, 1%); text-align:center; background-color:#f6f6f6; color:$red; @include transition(all .3s cubic-bezier(.4,0,0,1)); padding:0 0 40px 0 !important;
		@include breakpoint("mobile") {padding:30px 15px 30px 15px; @include rowMachine(1, 01%); margin-bottom:15px !important;}

		&:hover {
			background-color:$red; color:#fff;

			a {
				&::before {transform-origin: center left; transform: scaleX(1);}
				label {left:6px; color:$white;}
				&.btn-arrow svg {right:12px; fill:$white;}
			}
		}
	}

	.projet-info {padding:0 15px; min-height:203px;}

	.projet-image {
		position:relative; width:100%; height:250px; overflow:hidden;
		@include breakpoint("mobile") {height:150px;}
		div {position:absolute; top:0; left:0; bottom:0; right:0; background-size:cover; background-position:center center;}
	}
	
	h1 {
		font-size:36px; text-align:center; margin-bottom:25px;
		@include breakpoint("mobile") {margin-top:30px; font-size:28px;}
	}
	p {text-align:center; font-size:16px; transition-delay:0s !important;}
	.projet-link {display:block; position:absolute; top:0; left:0; bottom:0; right:0; background-color:#ff6600; opacity:0; z-index:100;}

}

.projets {
	position:relative; margin-top:30px; z-index:0; overflow:hidden; text-align:left;

	.projet {
		&::after {content:""; display:block; position:absolute; top:0; left:0; bottom:0; right:0; background-color:$red; opacity:0; z-index:100; pointer-events:none; @include transition(opacity .3s cubic-bezier(.4,0,0,1));}
		&:hover::after {opacity:0.4;}
		@include rowMachine(4, 1%); background:$red; background-size:cover; padding: 10px; float: left;
		@include breakpoint("mobile") {@include rowMachine(2, 1%);}

	}

	.projet-container {display:block; position:relative; height:0; overflow:hidden; padding-top:198px / 198px * 100%;}
	.projet-content {display:block; position: absolute; top: 0; left: 0; width: 100%; height: 100%;	text-align:center; padding-top:30px;}
}

.projets-client {
	position:relative; margin-top:30px; z-index:0; overflow:hidden; text-align:left;

	.projet-client {
		@include rowMachine(4, 1%); background:#fff; background-size:cover; padding: 10px; float: left;
		@include breakpoint("mobile") {@include rowMachine(2, 1%);}		
	}
	.projet-container {display:block; position:relative; height:0; overflow:hidden; padding-top:198px / 198px * 100%;}
	.projet-content {display:block; position: absolute; top: 0; left: 0; width: 100%; height: 100%;	text-align:center; padding-top:30px;}
}


.slider-logos {
	padding:60px 15px !important;
	@include breakpoint("mobile") {padding:30px 15px !important;}
}
.logos-left, .logos-right {
	position:absolute; top:50%; margin-top:-27px; z-index:100; left:15px; border:none; -webkit-appearance:none; -moz-appearance:none; appearance:none; width:29px; height:54px; background:url(../images/arrow-logos.png) top left no-repeat; background-size:cover;
	@include breakpoint("mobile") {display:none !important;}
}
.logos-right {left:auto; right:15px; transform:scaleX(-1);}
.slick-slider {
	width:calc(100% - 148px); margin:0 auto;
	@include breakpoint("mobile") {width:100%;}
}
.slick-slide {
	text-align:center;
	img {display:inline-block !important;}
}


.team {
	.employe-container {
		position:relative; float:left; width:25%;
		@include breakpoint("mobile") {width:50%;}
		@include breakpoint("smallmobile") {width:100%;}
	}
	.employe {
		position:relative; height: 0; overflow: hidden; padding-top: 706px / 720px * 100%; background:white;
		&::after {content:""; display:block; position:absolute; top:0; left:0; bottom:0; right:0; background-color:#000; opacity:0.2; z-index:10; @include transition(opacity .3s cubic-bezier(.4,0,0,1));}
		&:hover {
			&::after {opacity:0;}
		}
	}
	.employe-content {
		position: absolute; top: 0; left: 0; width: 100%; height: 100%;
		.employe-info {
			position:absolute; bottom:35px; left:25px; right:25px; overflow:hidden; z-index:15; pointer-events:none;
			h5 {position:relative; margin:0; padding:0; font-size:16px; z-index:2; display:inline-block; pointer-events:none;}
			p {position:relative; margin:5px 0 0 0; padding:0; font-size:14px; font-family: "Times New Roman", Times, serif; letter-spacing: 2px; z-index:2;  pointer-events:none; line-height:1em;}
		}
		span {display:block; position:absolute; top:0; left:0; right:0; bottom:0; background-size:cover; z-index:0;}
	}
}


form {
	position:relative; width:100%; max-width:610px; margin:30px auto 0 auto;
	.form-col {
		float:left; width:50%;
		@include breakpoint("mobile") {width:100%;}
		input, textarea, select {
			width:100%; padding:10px 15px; margin-bottom:10px; font-size:16px; background:none; background-color:rgba(#fff, 0.5); border:none; -webkit-appearance:none; -moz-appearance:none; appearance:none;
		}

		select {border-radius:0; color:#000;}

		textarea#projets {height:137px;}
		
		&:first-child {
			padding-right:10px;
			@include breakpoint("mobile") {padding-right:0;}
		}
		&:last-child {
			padding-left:10px;
			@include breakpoint("mobile") {padding-left:0;}
		}

		input[type="file"] {
			display:none; color: transparent; border:none; padding:0; margin-bottom:10px; height:0px; box-sizing:border-box; overflow:hidden;
			&::-webkit-file-upload-button {visibility: hidden;}
			&::before {box-sizing:border-box; width:100%; font-size:16px; background:none; border:none; -webkit-appearance:none; -moz-appearance:none; appearance:none; content: 'Joindre ton CV'; color: #fff; display: inline-block; background-color:$red; border-radius: 0px; padding: 11px 15px; outline: none; white-space: nowrap; -webkit-user-select: none; cursor: pointer; font-weight: 700;}
			&.file-en::before {content: 'Attach your resume';}
			&:hover::before {background-color:$white; color:$red;}
			&:active {outline: 0;}
			&:active::before {background:$red;}
		}

	}
	button {
		position:relative; display:inline-block; margin-top:20px;

		.loader {
			/*display:none;*/ position:absolute; top:0; left:0; bottom:0; right:0; background-color:$red; z-index:100; opacity:0; pointer-events:none;
			.waveover {transform:scale(0.5); top:15px;}
			.wave-container {top:0;}
			path {stroke:#fff;}
		}

		.success {
			display:none; position:absolute; top:0; left:0; bottom:0; right:0; background-color:$red; z-index:105;
			img {margin-top:6px;}
		}
	
	}

	::placeholder {color:#000;}
	:-ms-input-placeholder {color:#000;}
	::-ms-input-placeholder {color:#000;}
}

#map-container {
	z-index:440;
	@include breakpoint("mobile") {position:relative; height:250px;}
}

.select-container {
	position:relative;
	svg {display:block; position:absolute; top:12px; right:12px; height:16px; fill:#000; transform:rotate(90deg);}
}

.contact-info {
	.bg-elements {
		@include breakpoint("mobile") {display:block; position:relative;}	
	}
	p {line-height:1.3em;}
	strong {font-size:20px;}
}

.btn {
	position:relative; display:inline-block; padding:9px 45px 10px 18px; text-decoration:none; overflow:hidden; border:none; -webkit-appearance:none; -moz-appearance:none; appearance:none;
	label {position:relative; z-index:1; left:0; @include transition(all .3s cubic-bezier(.4,0,0,1)); transition-delay: 0s; pointer-events:none;}

	&.btn-arrow > svg {display:block; position:absolute; top:12px; right:18px; height:16px; fill:#fff; @include transition(all .3s cubic-bezier(.4,0,0,1)); transition-delay: .06s;}
	&.btn-red {
		background-color:$red; color:#fff;
		&::before {content:""; position:absolute; top:0; left:0; bottom:0; right:0; display:block; background-color:$grey; z-index:0; transform: scaleX(0); transform-origin:center right; transition: transform .3s cubic-bezier(.4,0,0,1);}
	}

	&.btn-black-over {
		&::before {background-color:#000;}
	}
	
	&.btn-white {
		background-color:#fff; color:$red;
		&::before {content:""; position:absolute; top:0; left:0; bottom:0; right:0; display:block; background-color:#000; z-index:0; transform: scaleX(0); transform-origin:center right; transition: transform .3s cubic-bezier(.4,0,0,1);}
	}
	
	&.btn-arrow.btn-white svg {fill:$red;}

	&.btn-red:hover {
		&::before {transform-origin: center left; transform: scaleX(1);}
		label {left:6px; color:$red;}
		&.btn-arrow svg {right:12px; fill:$red;}
	}

	&.btn-black-over:hover {
		label {color:#fff;}
		&.btn-arrow svg {fill:#fff;}
	}

	&.btn-white:hover {
		&::before {transform-origin: center left; transform: scaleX(1);}
		label {left:6px; color:$white;}
		&.btn-arrow svg {right:12px; fill:$white;}
	}
}


.contact-text {color:#fff; font-size:30px; line-height:1.3em;}
.nobureaux {
	div {
		float:left; width:33.33%;
		@include breakpoint("mobile") {width:100%;}	
	}
	a {color:#000;}
}

footer {
	position:relative; left:94px; width:calc(100% - 94px); background-color:#fff; padding:30px 15px; text-align:center;
	@include breakpoint("mobile") {left:60px; width:calc(100% - 60px);}
}


//@include transition(transform .3s ease);
//@include breakpoint("smallmobile") {width:222px;}
